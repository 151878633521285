import React from "react";
import styled from "styled-components";
import { graphql } from "gatsby";

import Banner from "../components/Banner";
import Layout from "../components/Layout";
import StyledHero from "../components/StyledHero";
import ProjectWithUs from "../components/Contact/ProjectWithUs";
import SEO from "../components/SEO";

// eslint-disable-next-line react/display-name
export default ({ data }) => (
  <Layout>
    <SEO title="About" description="About Fulcrum Growth" />
    <StyledHero
      img={data.aboutImage.childImageSharp.fluid}
      backgroundColor="0.1"
    >
      <Banner title="About" />
    </StyledHero>
    <AboutWrapper>
      <div>This is where we talk about us.</div>
    </AboutWrapper>
    <ProjectWithUs />
  </Layout>
);

const AboutWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-gap: 20px;

  padding: 2rem;
`;

export const getAboutImage = graphql`
  query {
    aboutImage: file(relativePath: { eq: "block-island.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
